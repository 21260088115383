export const ComponentSharedStateConst = {
  contractDetailsData: {
    name: 'contractDetails_Data',
    property: 'currentContract',
  },
  contractDetailsInvoice: {
    name: 'contractDetails_Invoice',
    property: 'invoiceCount',
  },
  contractDetailsProduct: {
    name: 'contractDetails_Product',
    property: 'productCount',
  },
  contractDetailsProductForOffHire: {
    name: 'contractDetails_Product_Off_Hire',
    property: 'products',
  },
  invoiceDetailsData: {
    name: 'invoiceDetails_Data',
    property: 'currentInvoice',
  },
  invoiceDetailsContract: {
    name: 'invoiceDetails_Contract',
    property: 'contractCount',
  },
};
