import { Injectable } from '@angular/core';
import { CookieBotConsentPayloadModel } from '@boels-core/models/cookie-bot.model';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Immutable } from '@boels-shared/types/ngxs-immutable.type';
import {
  InitCookieBotInstance,
  RemoveComponentState,
  SaveComponentState,
  SaveCookieBotSettings,
  ToggleLoading,
} from '../actions/shared.action';

export interface SharedStateModel {
  isLoading: boolean;
  component: Immutable<Record<string, Object>>;
  cookieBotState: Immutable<CookieBotConsentPayloadModel>;
  cookieBotInitialized: Readonly<boolean>;
}

@State<SharedStateModel>({
  name: 'shared',
  defaults: {
    isLoading: null,
    component: null,
    cookieBotState: null,
    cookieBotInitialized: false,
  },
})
@Injectable()
export class SharedState {
  @Selector()
  public static getIsLoading(state: SharedStateModel): boolean {
    return state.isLoading;
  }

  @Selector()
  public static getCookieBotState(state: SharedStateModel): Immutable<CookieBotConsentPayloadModel> {
    return state?.cookieBotState;
  }

  @Selector()
  public static isCookieBotInitialized(state: SharedStateModel): Readonly<boolean> {
    return state?.cookieBotInitialized;
  }

  public static getComponentState(component: string, property: string): any | null {
    return createSelector([SharedState], (state: SharedStateModel) => {
      const componentState: any = state?.component?.[component];
      return componentState ? componentState[property] ?? null : null;
    });
  }

  @Action(ToggleLoading)
  public setToggleLoading({ patchState }: StateContext<SharedStateModel>, action: ToggleLoading): void {
    patchState({
      isLoading: action.showLoading,
    });
  }

  /**
   * Saves component state with object up to the developer.
   * @param setState
   * @param dispatch
   * @param patchState
   * @param getState
   * @param action
   */
  @Action(SaveComponentState)
  public saveComponentState(
    { setState, dispatch, patchState, getState }: StateContext<SharedStateModel>,
    action: SaveComponentState
  ): void {
    patchState({
      component: {
        ...getState()?.component,
        [action.componentName]: action?.state,
      },
    });
  }

  /**
   * Removes component state depending on component name passed as string.
   * @param setState
   * @param dispatch
   * @param patchState
   * @param getState
   * @param action
   */
  @Action(RemoveComponentState)
  public removeComponentState(
    { setState, dispatch, patchState, getState }: StateContext<SharedStateModel>,
    action: RemoveComponentState
  ): void {
    if (getState()?.component?.hasOwnProperty(action.componentName)) {
      // We need copy of the state because of immutability.
      const copyState: Record<string, Object> = structuredClone(getState().component);
      delete copyState[action.componentName];
      patchState({
        component: copyState,
      });
    }
  }

  /**
   * Saves cookie consent settings into the store.
   * @param patchState
   * @param action
   */
  @Action(SaveCookieBotSettings)
  public setSaveCookieBotSettings({ patchState }: StateContext<SharedStateModel>, action: SaveCookieBotSettings): void {
    patchState({
      cookieBotState: action?.cookieBotPayload,
    });
  }

  /**
   * Sets value to true in the store when cookie instance is successfully loaded.
   * @param patchState
   * @param action
   */
  @Action(InitCookieBotInstance)
  public setInitCookieBotSettings({ patchState }: StateContext<SharedStateModel>, action: InitCookieBotInstance): void {
    patchState({
      cookieBotInitialized: true,
    });
  }
}
