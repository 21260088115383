import { CookieBotConsentPayloadModel } from '@boels-core/models/cookie-bot.model';

export class ToggleLoading {
  public static readonly type = '[Shared] Toggle Loading';

  constructor(public showLoading: boolean) {}
}

export class SaveComponentState {
  public static readonly type = '[SaveComponentState] Update/Save Component State';

  constructor(
    public componentName: string,
    public state: Object
  ) {}
}

export class RemoveComponentState {
  public static readonly type = '[RemoveComponentState] Remove Component State';

  constructor(public componentName: string) {}
}

export class TriggerComponentEvent {
  public static readonly type = '[TriggerComponentEvent] Trigger Component Event';

  constructor(public componentName: string) {}
}

export class SaveCookieBotSettings {
  public static readonly type = '[SaveCookieBotSettings] Save Cookie Bot Settings';

  constructor(public cookieBotPayload: CookieBotConsentPayloadModel) {}
}

export class InitCookieBotInstance {
  public static readonly type = '[InitCookieBotInstance] Init Cookie Bot';

  constructor() {}
}
