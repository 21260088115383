export const AppRoutesUrisConst = {
  Dashboard: (excludeDash?: boolean) => {
    return excludeDash ? 'dashboard' : '/dashboard';
  },
  Account: (excludeDash?: boolean) => {
    return excludeDash ? 'account' : '/account';
  },
  Products: (excludeDash?: boolean) => {
    return excludeDash ? 'products' : '/products';
  },
  Contracts: (excludeDash?: boolean) => {
    return excludeDash ? 'contracts' : '/contracts';
  },
  Invoices: (excludeDash?: boolean) => {
    return excludeDash ? 'invoices' : '/invoices';
  },
  NotFound: (excludeDash?: boolean) => {
    return excludeDash ? '404' : '/404';
  },
};
