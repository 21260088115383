import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, filter, switchMap, take } from 'rxjs';
import { DestroyReferenceDirective } from 'src/app/shared/directives/destroy-reference.directive';
import { environment } from 'src/environments/environment';
import { LocalizationService } from './localization.service';
import { WindowService } from './window.service';
import { ContextMeasurementService } from '@boels-shared/services/context-measurement.service';
import { SharedState } from '@boels-state/state/shared.state';
import { CookieBotConsentTypeEnum } from '@boels-core/enums/cookie-bot.enum';
import { CookieBotService } from '@boels-core/services/cookie-bot.service';
import { Store } from '@ngxs/store';

export interface WidgetInstance {
  culture: string;
  cultureCode: string;
  buttonId: string;
}

export interface WidgetConfig {
  deploymentId: string;
  widgetBaseURI: string;
  sandBox: string | null;
  hashCode: string;
  baseLiveAgentURI: string;
  widgetButtonsId: {
    NLButtonId: string;
    FRButtonId: string;
  };
}

declare global {
  interface Window {
    embedded_svc: any;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ChatSFWidgetService extends DestroyReferenceDirective {
  private readonly contextMeasurementService = inject(ContextMeasurementService);
  private readonly cookieBotService = inject(CookieBotService);
  private readonly localizationService = inject(LocalizationService);
  private readonly rendererFactory = inject(RendererFactory2);
  private readonly store = inject(Store);
  private readonly translateService = inject(TranslateService);
  private readonly windowService = inject(WindowService);

  private readonly renderer: Renderer2 = this.rendererFactory.createRenderer(null, null);

  /* -------------------------------------------------------------------------- */
  /*                           environmental variables                          */
  /* -------------------------------------------------------------------------- */
  private readonly salesForceChatConfig = environment.salesForceChat as WidgetConfig;

  private readonly widgetInstances: Array<WidgetInstance> = [
    {
      culture: 'nl-nl',
      cultureCode: 'MyBoels_NL',
      buttonId: this.salesForceChatConfig.widgetButtonsId.NLButtonId,
    },
    {
      culture: 'nl-be',
      cultureCode: 'MyBoels_NL',
      buttonId: this.salesForceChatConfig.widgetButtonsId.NLButtonId,
    },
    {
      culture: 'en-nl',
      cultureCode: 'MyBoels_NL',
      buttonId: this.salesForceChatConfig.widgetButtonsId.NLButtonId,
    },
    {
      culture: 'fr-be',
      cultureCode: 'MyBoels_FR',
      buttonId: this.salesForceChatConfig.widgetButtonsId.FRButtonId,
    },
    {
      culture: 'fr-lu',
      cultureCode: 'MyBoels_FR',
      buttonId: this.salesForceChatConfig.widgetButtonsId.FRButtonId,
    },
  ];

  public init(): void {
    combineLatest([
      this.store.select(SharedState.isCookieBotInitialized).pipe(filter((result) => result)),
      this.cookieBotService
        .getCookieBotConsentStateByProperty(CookieBotConsentTypeEnum.NECESSARY)
        .pipe(filter((result) => !!result)),
    ])
      .pipe(
        take(1),
        switchMap(() => this.localizationService.cultureValue$.pipe(filter((culture) => culture !== ''))),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((culture: string) => {
        if (!this.windowService.window) {
          return;
        }
        const instance: WidgetInstance | undefined = this.widgetInstances.find((i) => i.culture === culture);
        if (instance) {
          this.initChat(instance);
          return;
        }

        this.removeAllChatInstances();
      });
  }

  public toggleChat(state: boolean): void {
    const chatContainers: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName(
      'modalContainer'
    ) as HTMLCollectionOf<HTMLElement>;
    if (!this.windowService.window) {
      return;
    }
    const window: Window = this.windowService.window;

    if (state && window.embedded_svc !== undefined && typeof window.embedded_svc.hideHelpButton === 'function') {
      this.windowService.window.embedded_svc!.hideHelpButton();

      if (chatContainers.length) {
        chatContainers[0].style.display = 'none';
      }

      return;
    }

    if (
      !state &&
      window.embedded_svc !== undefined &&
      !chatContainers.length &&
      typeof window.embedded_svc.showHelpButton === 'function'
    ) {
      this.windowService.window.embedded_svc!.showHelpButton();
      return;
    }

    if (chatContainers.length) {
      chatContainers[0].style.display = 'block';
    }
  }

  private initChat(widgetInstance: WidgetInstance): void {
    if (this.windowService.window) {
      if (!this.windowService.window?.embedded_svc) {
        const s1 = this.renderer.createElement('script'),
          s0 = this.windowService.window.document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = `https://service.force.com/embeddedservice/5.0/esw.min.js`;
        this.renderer.setAttribute(s1, 'salesforce', 'set');
        this.renderer.insertBefore(s0?.parentNode, s1, s0);

        s1.onload = () => {
          this.initESW('https://service.force.com', widgetInstance);
        };
      } else {
        this.initESW('https://service.force.com', widgetInstance);
      }
    }
  }

  private initESW(gslbBaseURL: string | null, widgetInstance: WidgetInstance): void {
    if (!this.windowService.window) {
      return;
    }

    const window: Window = this.windowService.window;
    window.embedded_svc.settings.displayHelpButton = true; //Or false
    window.embedded_svc.settings.language = widgetInstance.culture; //For example, enter 'en' or 'en-US'

    window.embedded_svc.settings.defaultMinimizedText = 'Chat'; //this.translateService.instant('sfChat.defaultMinimizedText'); //(Defaults to Chat with an Expert)
    window.embedded_svc.settings.disabledMinimizedText = this.translateService.instant('sfChat.disabledMinimizedText'); //(Defaults to Agent Offline)

    window.embedded_svc.settings.loadingText = this.translateService.instant('sfChat.loadingText'); //(Defaults to Loading)
    //window.embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    //window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    //};
    //window.embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
    //window.embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    window.embedded_svc.settings.offlineSupportMinimizedText = this.translateService.instant(
      'sfChat.offlineSupportMinimizedText'
    ); //(Defaults to Contact Us)
    window.embedded_svc.settings.defaultAssistiveText = this.translateService.instant('sfChat.defaultAssistiveText'); //(Defaults to Live chat:)

    window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
    window.embedded_svc.settings.entryFeature = 'LiveAgent';

    window.embedded_svc.init(
      `https://${this.salesForceChatConfig.widgetBaseURI}${[this.salesForceChatConfig.sandBox].join(
        '.'
      )}.my.salesforce.com`,
      `https://${this.salesForceChatConfig.widgetBaseURI}${[this.salesForceChatConfig.sandBox].join(
        '.'
      )}.my.salesforce-sites.com/liveAgentSetupFlow`,
      gslbBaseURL,
      this.salesForceChatConfig.hashCode,
      widgetInstance.cultureCode,
      {
        baseLiveAgentContentURL: `https://c.${this.salesForceChatConfig.baseLiveAgentURI}/content`,
        deploymentId: this.salesForceChatConfig.deploymentId,
        buttonId: widgetInstance.buttonId,
        baseLiveAgentURL: `https://d.${this.salesForceChatConfig.baseLiveAgentURI}/chat`,
        eswLiveAgentDevName: widgetInstance.cultureCode,
        isOfflineSupportEnabled: false,
      }
    );

    window.embedded_svc.addEventHandler('onHelpButtonClick', (data: any) => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_help_clicked');
    });

    window.embedded_svc.addEventHandler('onChatRequestSuccess', (data: any) => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_requested');
    });

    window.embedded_svc.addEventHandler('onChatConferenceInitiated', (data: any) => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_initiated');
    });

    window.embedded_svc.addEventHandler('onChatConferenceEnded', (data: any) => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_ended');
    });

    window.embedded_svc.addEventHandler('onChatTransferSuccessful', (data: any) => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_transferred');
    });

    window.embedded_svc.addEventHandler('onIdleTimeoutOccurred', (data: any) => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_timeout');
    });

    window.embedded_svc.addEventHandler('onClickSubmitButton', (data: any) => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_offline_submitted');
    });

    window.embedded_svc.addEventHandler('afterMaximize', () => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_window_opened');
    });

    window.embedded_svc.addEventHandler('afterMinimize', () => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_window_minimized');
    });

    window.embedded_svc.addEventHandler('afterDestroy', () => {
      this.contextMeasurementService.setSalesforceChatEvent('chat_window_closed');
    });
  }

  private removeAllChatInstances(): void {
    if (!this.windowService.window) {
      return;
    }

    const window: Window = this.windowService.window;
    if (window.embedded_svc !== undefined) {
      this.windowService.window.embedded_svc!.hideHelpButton();
    }
  }
}
