import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { Config } from './config.model';

@Injectable()
export class AppConfigService {
  public appConfig!: Config;

  constructor(private readonly http: HttpClient) {}

  public async loadAppConfig(): Promise<any> {
    const uriConstructsJSON: string = '/assets/data/appConfig.json';

    return lastValueFrom(this.http.get(uriConstructsJSON)).then((data: Config | any) => {
      this.appConfig = data;
      this.appConfig['baseUrl'] = environment.baseUrl;
    });
  }
}
