import { inject, Injectable } from '@angular/core';
import { CookieBotConsentPayloadModel, CookieBotInstanceModel } from '@boels-core/models/cookie-bot.model';
import { WindowService } from '@boels-core/services/window.service';
import { CookieBotConsentPropertyType } from '@boels-core/types/cookie-bot.type';
import { Immutable } from '@boels-shared/types/ngxs-immutable.type';
import { isAppRunningLocally } from '@boels-shared/utils/helper.utils';
import { InitCookieBotInstance, SaveCookieBotSettings } from '@boels-state/actions/shared.action';
import { SharedState } from '@boels-state/state/shared.state';
import { Store } from '@ngxs/store';
import { filter, map, Observable, skip } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ContextMeasurementService } from '@boels-shared/services/context-measurement.service';
import { ComponentSharedStateConst } from '@boels-shared/constants/component-shared-state.const';

@Injectable({
  providedIn: 'root',
})
export class CookieBotService {
  private readonly contextMeasurementService = inject(ContextMeasurementService);
  private readonly router = inject(Router);
  private readonly store = inject(Store);
  private readonly windowService = inject(WindowService);

  private readonly localhostCookiebotSettings: CookieBotConsentPayloadModel = {
    method: 'LOCALHOST',
    stamp: 'LOCALHOST',
    preferences: true,
    marketing: true,
    statistics: true,
    necessary: true,
  };

  /**
   * Returns cookie bot instance.
   */
  public get cookieBotInstance(): CookieBotInstanceModel {
    return (this.windowService.window as any)?.Cookiebot as CookieBotInstanceModel;
  }

  /**
   * Returns cookie bot consent data as plain data, not observable.
   */
  public get getCookieBotConsentState(): Immutable<CookieBotConsentPayloadModel> {
    return this.store.selectSnapshot(SharedState.getCookieBotState);
  }

  public readonly getCookieBotConsentState$: Observable<Immutable<CookieBotConsentPayloadModel>> = this.store.select(
    SharedState.getCookieBotState
  );

  private readonly productCount$: Observable<number> = this.store.select(
    SharedState.getComponentState(
      ComponentSharedStateConst.contractDetailsProduct.name,
      ComponentSharedStateConst.contractDetailsProduct.property
    )
  );

  private cookieBotTimerInitChecked: ReturnType<typeof setInterval>;

  /**
   * Tries to grab cookie bot instance, sets store when cookie bot
   * init and saves current consent into the store.
   * When app is launched locally via localhost we mimic
   * cookie bot init done with all parameters accepted.
   */
  public initCookieBotListeners(): void {
    // For app running in local host we fake like cookie bot instance
    // is loaded and everything has been accepted.
    if (isAppRunningLocally()) {
      this.store.dispatch(new InitCookieBotInstance());
      this.store.dispatch(new SaveCookieBotSettings(this.localhostCookiebotSettings));
      return;
    }

    this.cookieBotTimerInitChecked = setInterval(() => {
      if (this.cookieBotInstance) {
        clearInterval(this.cookieBotTimerInitChecked);
        this.store.dispatch(new InitCookieBotInstance());
        this.store.dispatch(new SaveCookieBotSettings(structuredClone(this.cookieBotInstance?.consent)));
      }
    }, 10);

    // This listens to accept/reject or any change for cookie bot options and updates store.
    this.windowService.window?.addEventListener('CookiebotOnLoad', () => {
      this.store.dispatch(new SaveCookieBotSettings(structuredClone(this.cookieBotInstance?.consent)));
    });
  }

  /**
   * Bind consent events to routing
   */
  public initConsentEvents(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        skip(1)
      )
      .subscribe(() => {
        if (!this.cookieBotInstance?.hasResponse) {
          return;
        }

        this.contextMeasurementService.setCookieConsentUpdate();
      });
  }

  /**
   * Shows cookie bar view.
   */
  public showCookieBarView(): void {
    this.cookieBotInstance?.show();
  }

  /**
   * Updates cookie consent options on the fly without even showing cookie bot view.
   * @param customConsentData
   */
  public updateCookiePreferences(customConsentData: Partial<CookieBotConsentPayloadModel>): void {
    const finalData = {
      ...this.store.selectSnapshot(SharedState.getCookieBotState),
      ...customConsentData,
    };
    this.cookieBotInstance?.submitCustomConsent(finalData?.preferences, finalData?.statistics, finalData?.marketing);
  }

  /**
   * Returns cookie bar consent based on passed parameter.
   * @param property
   */
  public getCookieBotConsentStateByProperty(property: CookieBotConsentPropertyType): Observable<boolean | string> {
    return this.getCookieBotConsentState$.pipe(
      filter((result) => !!result),
      map((result) => {
        return (result as any)[property];
      })
    );
  }
}
